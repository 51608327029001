























































































































































































































import { Component, Prop } from 'vue-property-decorator';
import MixinMain from '@/mixing/MixinMain.vue';
import AppContainerBase from '@/components/util/AppContainerBase.vue';
import Util from '@/utils/Util';
import { internet } from '@/utils/Internet';
import { IDataTable } from '@/model/main/IDataTable';
import { IHeaderTable } from '@/model/main/IHeaderTable';
import DataTable from '@/components/util/DataTable.vue';
import { ILabels } from '@/model/labels/ILabelsEvaluacion';
import EvaluationDataTable from '@/components/agent/EvaluationDataTable.vue';
import { IItemSelect } from '@/model/util/IItemSelect';
import { Icon } from '@/model/util/Icon';
import { IHabilidades } from '@/model/habilidades/IHabilidades';
import { utils, writeFile } from 'xlsx';
    @Component({
        name: 'MainDetailHabilidadesBlandas',
        components: {
                AppContainerBase,
                DataTable,
                EvaluationDataTable
        }})
    export default class MainDetailHabilidadesBlandas extends MixinMain {
        public header: Array<IHeaderTable<IDataTable>> = [];
        public rows: Array<IDataTable> = [];
        public ilabels: Array<ILabels> = [];
        public dialogDetails = false; 
		public titleHabilidades = this.$t("HabilidadesBlandas.titleHabilidades");
        public message6_d = this.$t("Email.msgEmail6");
        public tunning = this.$t("HabilidadesBlandas.Tunning");
        public isLoading = false;
        public headerEvaluation: Array<IHeaderTable<IDataTable>> = [
			{text: 'Subcategoria', value: 'subcategoria'},
			{text: 'Cumple', value: 'cumple'},
			{text: 'Tunning', value: 'tunning'},
			{text: 'Comentario', value: 'comentario'},
		];
        public rowsEvaluation: Array<IDataTable> = [];
		public searchText = this.$t("DataTable.searchText");
		public search = '';
		public noCumple = this.$t("DataTable.noCumple");
		public cumple = this.$t("DataTable.cumple");
		public countElementPage = this.$t("DataTable.countElementPage");
		public elementPerPage = this.$t("DataTable.elementPerPage");
		public audio_nombre_label = this.$t("HabilidadesBlandas.audioNombre");
		public audio_nombre_detail = "";
		public campania_label = this.$t("HabilidadesBlandas.campania");
		public campania_detail = "";
		public tipo_llamada_label = this.$t("HabilidadesBlandas.titleTipoLlamado");
		public tipo_llamada_detail = "";
		public btnGuardar = this.$t("HabilidadesBlandas.guardar");
		public token = '';
		public audio_path = "";
		public ruta = '';
		public backToText = this.$t("MainAgent.textBackTo");
        

        mounted(): void{
			this.audio_path = process.env.VUE_APP_API;
			if (localStorage.getItem('TOKEN') != null) {
				this.token = localStorage.getItem('TOKEN') as string;
			} 
            console.log(this.$route.params.agente)
            this.getData()
        }

        private getData(): void {
			this.isLoading = true;
            this.dialogDetails = false;
			const request_1 = internet
				.newRequest()
				.get(`habilidades/getDetailHabilidades?agente=${this.$route.params.agente}`);
            
            Util.waitForPromises([request_1])
				.then((responses) => {
					const data = responses[0].data;
					this.isLoading =false;
                    console.log(data);
                    const dataTable: Array<IDataTable> = [];
                        for (let item of data) {
						console.log(item);
                        const row: IDataTable = {};
                        row['nombre_audio'] = item.nombre_audio;
                        row['fluidez'] = (item.fluidez.cumple ? 'Si' : 'No')
                        row['atencion'] = item.atencion ? 'Si' : 'No';
                        row['modulacion'] = item.modulacion ? 'Si' : 'No';
						row['amabilidad'] = item.amabilidad.cumple ? 'Si' : 'No';
                        row['serenidad'] = item.serenidad.cumple ? 'Si' : 'No';
						row['ruta'] = item.ruta;
						row['campania'] = item.campania;
						row['tipo_llamada'] = item.tipo_llamada;
                        row[this.tunning as string] = "";
                        dataTable.push(row);
                    }
					const header: Array<IHeaderTable<IDataTable>> = [];
					for (let key of Object.keys(dataTable[0])) {
						let text = key;
						if (text == 'ruta' || text == 'campania' || text == 'tipo_llamada') {
							continue
						}
						switch (key) {
							case 'nombre_audio':
								text = this.$t("HabilidadesBlandas.audioNombre") as string;
								break;
							case 'atencion':
								text = this.$t("HabilidadesBlandas.atencion") as string;
								break;
							case 'amabilidad':
								text = this.$t("HabilidadesBlandas.amabilidad") as string;
								break;
							case 'fluidez':
								text = this.$t("HabilidadesBlandas.fluidez") as string;
								break;
							case 'modulacion':
								text = this.$t("HabilidadesBlandas.modulacion") as string;
								break;
							case 'serenidad':
								text = this.$t("HabilidadesBlandas.serenidad") as string;
								break;
						}
						header.push({
							text,
							value: key,
						});
					}
                    /*const header: Array<IHeaderTable<
						IDataTable
					>> = Object.keys(dataTable[0]).map(
						(
							key: string
						): IHeaderTable<IDataTable> => {
							let text = key;
							if (text == 'case') {
								return null;
							}
							switch (key) {
                                case 'nombre_audio':
									text = this.$t("HabilidadesBlandas.audioNombre") as string;
									break;
								case 'atencion':
									text = this.$t("HabilidadesBlandas.atencion") as string;
									break;
                                case 'amabilidad':
									text = this.$t("HabilidadesBlandas.amabilidad") as string;
									break;
                                case 'fluidez':
									text = this.$t("HabilidadesBlandas.fluidez") as string;
									break;
                                case 'modulacion':
									text = this.$t("HabilidadesBlandas.modulacion") as string;
									break;
                                case 'serenidad':
									text = this.$t("HabilidadesBlandas.serenidad") as string;
									break;
                                }
							return {
								text,
								value: key,
							};
						}
					) as Array<IHeaderTable<IDataTable>>;*/
					this.rows = dataTable;
					this.header = header;
					
					
				})
				.catch(console.log)
				.finally(() => {
					//this.isLoading = false;
				});
        }

        public openTunning(item:any){
			console.log(item);
			this.ruta = item.item.ruta;
			this.audio_nombre_detail = item.item.nombre_audio;
			this.campania_detail = item.item.campania;
			this.tipo_llamada_detail = item.item.tipo_llamada;
			this.rowsEvaluation = [
				{
					'subcategoria': 'Amabilidad',
					'cumple': item.item.amabilidad,
					'tunning': "",
					'comentario': ""
				},
				{
					'subcategoria': 'Atencion',
					'cumple': item.item.atencion,
					'tunning': "",
					'comentario': ""
				},
				{
					'subcategoria': 'Fluidez',
					'cumple': item.item.fluidez,
					'tunning': "",
					'comentario': ""
				},
				{
					'subcategoria': 'Modulacion',
					'cumple': item.item.modulacion,
					'tunning': "",
					'comentario': ""
				},
				{
					'subcategoria': 'Serenidad',
					'cumple': item.item.serenidad,
					'tunning': "",
					'comentario': ""
				},
			]
			this.dialogDetails = true; 
			//this.elementSub = item.subcategoria         
		}

		public guardarCambios() {
			let datos = [];
			for (let item of this.rowsEvaluation) {
				if (item.tunning != "") {
					let data = {
						'subcategoria': item.subcategoria,
						'cumple': item.tunning,
						'comentario': item.comentario,
					};
					datos.push(data)
				}
			}
			console.log("Request de audio", this.audio_nombre_detail);
			console.log("Data: ", datos);
			const request_1 = internet
				.newRequest()
				.post(`habilidades/changeDetailHabilidades?audio=${this.audio_nombre_detail}`, {data: datos});
            Util.waitForPromises([request_1])
				.then((responses) => {
					console.log("Datos modificados");
					this.getData();
					this.dialogDetails = false;
					Util.showMessage(
						this.$t("HabilidadesBlandas.msgGuardado") as string,
						Icon.SUCCESS
					);
				})
				.catch(console.log)
				.finally(() => {
					//this.isLoading = false;
				});
		}

		public getItemCumple(robot: string): Array<IItemSelect> {
			const array: Array<IItemSelect> = [
				{
					text: this.$t("DataTable.sinCambio") as string,
					value: '',
				},
			];
			if (robot === 'No') {
				array.push({
					text: this.$t("DataTable.cumple") as string,
					value: '1',
				});
			} else {
				array.push({
					text: this.$t("DataTable.noCumple") as string,
					value: '0',
				});
			}
			return array;
		}

		public getTextCountElementPerPage(
			to: number,
			end: number
		): string {
			let text = Util.replaceTextWith(
				this.countElementPage as string,
				'$1',
				to.toString()
			);
			text = Util.replaceTextWith(
				text,
				'$2',
				end.toString()
			);
			return text;
		}

		public back() {
			this.$router.push({
				name: 'MainHabilidadesBlandas',
			});
		}




    }
