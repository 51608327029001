var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-container-base',{attrs:{"title":_vm.titleHabilidades}},[_c('div',[_c('v-col',[(_vm.isLoading)?_c('v-card',[_c('v-card-text',{staticClass:"text-center py"},[_c('v-progress-circular',{attrs:{"size":100,"width":10,"indeterminate":"","color":"#142730"}})],1)],1):_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"name":"evaluations","item-key":"name","headers":_vm.header,"items":_vm.rows,"search":_vm.search,"header-props":{ sortIcon: null },"footer-props":{
										'items-per-page-text': _vm.elementPerPage,
									}},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',[_c('td',[_vm._v(_vm._s(row.item.nombre_audio))]),_c('td',[_vm._v(_vm._s(row.item.fluidez))]),_c('td',[_vm._v(_vm._s(row.item.atencion))]),_c('td',[_vm._v(_vm._s(row.item.modulacion))]),_c('td',[_vm._v(_vm._s(row.item.amabilidad))]),_c('td',[_vm._v(_vm._s(row.item.serenidad))]),_c('td',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openTunning(row)}}},[_vm._v("mdi-email-edit-outline > mdi-file-document-multiple-outline ")])],1)])]}},{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":_vm.searchText,"append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"footer.page-text",fn:function(ref){
							var pageStart = ref.pageStart;
							var pageStop = ref.pageStop;
return [_vm._v(" "+_vm._s(_vm.getTextCountElementPerPage(pageStart, pageStop))+" ")]}}])})],1)],1),_c('v-dialog',{attrs:{"width":"1200","persistent":""},model:{value:(_vm.dialogDetails),callback:function ($$v) {_vm.dialogDetails=$$v},expression:"dialogDetails"}},[_c('v-card',[_c('div',{staticStyle:{"overflow-x":"hidden","overflow-y":"hidden"}},[_c('div',{staticClass:"pt-5 px-5"},[_c('audio',{staticStyle:{"width":"100%"},attrs:{"id":"audio","controls":"","src":(_vm.audio_path + "/rutaAudioSftp/?token=" + _vm.token + "&ruta=" + _vm.ruta + _vm.audio_nombre_detail)}})]),_c('v-card-title',{attrs:{"primary-title":""}}),_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"md":"10","lg":"11","cols":"12"}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.audio_nombre_label)+": "+_vm._s(_vm.audio_nombre_detail)+" ")])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.campania_label)+": "+_vm._s(_vm.campania_detail)+" ")])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.tipo_llamada_label)+": "+_vm._s(_vm.tipo_llamada_detail)+" ")])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"md":"10","lg":"11","cols":"12"}},[_c('v-data-table',{attrs:{"items":_vm.rowsEvaluation,"headers":_vm.headerEvaluation,"loading":_vm.isLoading,"footer-props":{
										'items-per-page-text': _vm.elementPerPage,
									}},scopedSlots:_vm._u([{key:"item.cumple",fn:function(ref){
									var item = ref.item;
return [(item.cumple === 'No')?_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" "+_vm._s(_vm.noCumple)+" ")]):_c('v-chip',{attrs:{"color":"green","dark":""}},[_vm._v(" "+_vm._s(_vm.cumple)+" ")])]}},{key:"item.tunning",fn:function(ref){
									var item = ref.item;
return [_c('v-select',{attrs:{"dense":"","color":"#06B7B2","items":_vm.getItemCumple(item.cumple),"filled":""},model:{value:(item.tunning),callback:function ($$v) {_vm.$set(item, "tunning", $$v)},expression:"item.tunning"}})]}},{key:"item.comentario",fn:function(ref){
									var item = ref.item;
return [_c('v-text-field',{attrs:{"color":"#06B7B2","type":"text","dense":"","disabled":item.tunning === '',"outlined":""},model:{value:(item.comentario),callback:function ($$v) {_vm.$set(item, "comentario", $$v)},expression:"item.comentario"}},[(item.tunning != '')?_c('v-icon',{attrs:{"slot":"append","color":"red"},on:{"click":function($event){item.comentario = '';}},slot:"append"},[_vm._v("mdi-delete-forever-outline")]):_vm._e()],1)]}},{key:"footer.page-text",fn:function(ref){
										var pageStart = ref.pageStart;
										var pageStop = ref.pageStop;
return [_vm._v(" "+_vm._s(_vm.getTextCountElementPerPage(pageStart, pageStop))+" ")]}}])})],1)],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"green","text":""},on:{"click":function($event){return _vm.guardarCambios()}}},[_vm._v(" "+_vm._s(_vm.btnGuardar)+" ")]),_c('v-btn',{attrs:{"outlined":"","color":"red","text":""},on:{"click":function($event){_vm.dialogDetails = false}}},[_vm._v(" "+_vm._s(_vm.message6_d)+" ")])],1)],1)])],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12","md":"4","lg":"4"}},[_c('v-btn',{attrs:{"color":"#06B7B2","dark":"","block":"","link":""},on:{"click":function($event){return _vm.back()}}},[_vm._v(" "+_vm._s(_vm.backToText)+" ")])],1),_c('v-spacer')],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }